<template>
  <div class="supervisor-title">
    <iq-title class-name="desktopHeader">
      {{ title }}
    </iq-title>
  </div>
</template>

<script>
import IqTitle from '@/views/ui/components/typography/IqTitle'

export default {
  name: 'SupervisorTitle',
  components: { IqTitle },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass">
.supervisor-title
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px 0
</style>
