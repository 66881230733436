var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supervisor-title" },
    [
      _c("iq-title", { attrs: { "class-name": "desktopHeader" } }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }